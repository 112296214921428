<template>
  <div class="nav-bar">
    <div class="logo">Evergreen</div>
    <div class="nav">
      <div
        class="nav-item"
        v-for="(item, index) in navs"
        :key="index"
        @click="pageRouter(item.path, index)"
        :class="$route.path === item.path ? 'active' : ''"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        { path: "/", title: "Homepage" },
        { path: "/contact", title: "Contact Us" },
        { path: "/privacy", title: "Privacy Policy" },
        { path: "/service", title: "Terms of service" },
      ],
      currentIdx: 0,
    };
  },
  methods: {
    pageRouter(path, index) {
      this.currentIdx = index;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 25px;
  box-shadow: 0 2px 2px -2px rgba(128, 128, 128, 0.653);
  box-sizing: border-box;
  .logo {
    flex: 1;
    text-align: left;
    font-size: 26px;
    font-weight: bold;
    img {
      width: 100px;
      height: 20px;
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;
    width: 30%;
    .nav-item {
      cursor: pointer;
      &::hover {
        color: rgb(83, 169, 240);
      }
    }
    .active {
      color: rgb(13, 131, 227);
    }
  }
}
@media screen and (max-width: 800px) {
  .nav {
    display: none !important;
  }
}
</style>
