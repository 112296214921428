<template>
  <div class="home">
    <div class="module-one">
      <div>
        <h1>Evergreen</h1>
        <p>Evergreen will join hands with domestic high-quality games to launch overseas in the near future.</p>
        <p>Besides, a number of other games are ready to be launched. With strong game research and development capabilities and local marketing capabilities,we will continue to expand our overseas business in the future. Please look forward to it.</p>
      </div>
    </div>
    <div class="module-two">
      <div>
<!--        <h1>開發者介紹</h1>-->
        <p>In the future,continuously adhering to the strategic core of “focusing on users”,Evergreen will strive to create an unprecedented game experience for players around the world and to become the most popular game publisher.</p>
      </div>
    </div>
    <div class="module-three">
      <div>
        <h1>Contact Us</h1>
        <p>If you want to get more information or have any questions, please contact the following e-mail address.</p>
        <p>business@evergreenrealm.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="less" scoped>
.home {
  height: calc(100vh - 55px);
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
  &::-webkit-scrollbar {
    display: none;
  }
}
.module-one {
  background: url("../assets/image/bg1.png");
}
.module-two {
  background: url("../assets/image/bg2.png");
}
.module-three {
  background: url("../assets/image/bg3.png");
}
.module-one,
.module-two,
.module-three {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  background-size: 100% 100%;
  background-position: center center;
  h1 {
    font-size: 92px;
  }
  p {
    font-size: 28px;
  }
  h1,
  p {
    color: white;
  }
}
.module-two {
  h1,
  p {
    color: black;
  }
}
</style>
